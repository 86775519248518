<template>
	<div 
		class="branch friends pa-3"
	>
		<div
			class="mt-3"
		>
			<v-text-field
				v-model="search"
				placeholder="연락처 또는 이름을 입력하세요"
				hide-details
				label="검색"
				outlined
				append-icon="search"
				color="success2"
			>
			</v-text-field>
		</div>
		
		<v-divider
			class="mt-5 mb-5"
		></v-divider>
		
		<div
			class="text-caption"
		>
			등록 완료된 영업점
		</div>
		
		<v-card
			class="pa-0 mt-0"
			elevation="0"
		>
			<v-list
				v-for="(item, index) in list_fav"
				:key="'fav_' + index"
				
				class="pa-0 ma-0"
			>
				<v-list-item
					class="pa-0 ma-0"
				>
					<v-list-item-avatar
						class="pa-0 ma-0"
						size="60"
					>
						<v-icon 
							large
							:color="item.status_color"
						>mdi-account-circle</v-icon>
					</v-list-item-avatar>
					<v-list-item-content
						class="pa-0 ma-0"
					>
						<div
							class="warp-start"
						>
							<span class="text-subtitle-2 font-weight-bold">{{ item.name }}</span> <br/>
							<span class="text-caption">{{ item.tel }}</span>
							
							<v-btn
								icon
								class="star"
								color="success2"
								x-small
								@click="removeFav(item)"
							>
								<v-icon>mdi-check-circle</v-icon>
							</v-btn>
						</div>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		
			<v-divider
				class="mt-3 mb-3"
			></v-divider>
			
			<v-list
				v-for="(item, index) in list_normal"
				:key="index"
				class="pa-0 ma-0"
				:class="(item.hide) ? 'none' : ''"
			>
				<v-list-item
					class="pa-0 ma-0"
				>
					<v-list-item-avatar
						class="pa-0 ma-0"
						size="60"
					>
						<v-icon 
							:color="item.status_color"
							large
						>mdi-account-circle</v-icon>
					</v-list-item-avatar>
					<v-list-item-content
						class="pa-0 ma-0"
					>
						<div
							class="warp-start"
						>
							<span class="text-subtitle-2 font-weight-bold">{{ item.name }}</span> <br/>
							<span class="text-caption">{{ item.tel }}</span>
							
							<v-btn
								icon
								class="star"
								:color="item.fav ? 'success2' : ''"
								x-small
								@click="setFav(item)"
							>
								<v-icon>mdi-check-circle</v-icon>
							</v-btn>
						</div>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-card>
		
		<v-card
			v-if="hideAll"
			class="mt-5"
			>
			<v-card-text
				class="text-center"
			>
				조회된 연락처가 없습니다
			</v-card-text>
		</v-card>
		
	</div>
</template>

<script>
	export default{
		name: 'BranchFriends'
		,props: ['user', 'callBack']
		,created: function(){
			this.$emit('setProgram', this.program)
			this.onAppEvent()
			this.listenIos()
			
			window.addEventListener('scroll', () => {
				this.bottom = this.bottomVisible()
			})
			this.addBeer()
		}
		,data: function(){
			return {
				program: {
					title: '연락처 불러오기'
					,not_navigation: true
					,next: {
						title: '등록'
						,do: false
						,callBack: 'setFriend'
					}
				}
				,bottom: false
				,eventMessage: ''
				,search: null
				,hideAll: false
				,contactPage: 1
				,items: []
				,fav: [
				]
				,sample: [
					{
						name: '가나다'
						,tel: '01011112222'
						,status: 'apply'
					}
					,{
						name: '나다라'
						,tel: '01022223333'
					}
					,{
						name: '다라마'
						,tel: '01033334444'
					}
					,{
						name: '라마바'
						,tel: '01044445555'
					}
					,{
						name: '마바아'
						,tel: '01055556666'
						,status: 'wait'
					}
					,{
						name: '바아사'
						,tel: '01066667777'
					}
					,{
						name: '사아자'
						,tel: '01077778888'
					}
					,{
						name: '아자차'
						,tel: '01088889999'
						,status: 'wait'
					}
					,{
						name: '자차카'
						,tel: '01099990000'
					}
				]
			}
		}
		,computed: {
			list_fav: function(){
				return this.items.filter(function(item, index){
					let fav = localStorage.getItem(item.tel)
					
					if(!fav){
						item.fav = false
						return ''
					}
					
					if(item.status == 'none'){
						item.status_color = ''
					}else if(item.status == 'wait'){
						item.status_color = 'orange'
					}else if(item.status == 'apply'){
						item.status_color = 'success2'
					}
					
					item.name = decodeURIComponent(item.name)
					item.tel = decodeURIComponent(item.tel).replace('+82', '0').replace(/-/gi, '')
					item.phoneNumber = item.tel
					item.salesManager = item.name
					item.index = index
					
					return item
				})
			}
			,list_normal: function(){
				console.log('computed list_normal')
				return this.items.filter(function(item, index){
					let fav = localStorage.getItem(item.tel)
					
					if(fav){
						item.fav = true
						return ''
					}
					
					if(item.status == 'none'){
						item.status_color = ''
					}else if(item.status == 'wait'){
						item.status_color = 'orange'
					}else if(item.status == 'apply'){
						item.status_color = 'success2'
					}
					
					item.name = decodeURIComponent(item.name)
					item.tel = decodeURIComponent(item.tel).replace('+82', '0').replace(/-/gi, '')
					item.phoneNumber = item.tel
					item.index = index
					
					return item
				})
			}
		}
		,methods: {
			bottomVisible() {
				const scrollY = window.scrollY
				const visible = document.documentElement.clientHeight
				const pageHeight = document.documentElement.scrollHeight
				const bottomOfPage = visible + scrollY >= pageHeight
				return bottomOfPage || pageHeight < visible
			}
			,onAppEvent: function(){
		
				const NUA = navigator.userAgent.toLowerCase()
				
				if(NUA.indexOf('android') > -1){
					
					try{
						const list = JSON.parse(window.Android.getContactList(this.contactPage))
				
						let currentPage = list.currentPage
						let totalPage = list.totalPage
						let data = list.data

						console.log(currentPage + " : " + totalPage)
						
						if(data.length > 0){
							this.items = this.items.concat(data)
						}else{
							console.log(11)
						}
						
						this.contactPage++
					}catch(e){
						console.log(e)
						this.items = this.sample
					}
					
				}else if(
					NUA.indexOf('iphone') > -1
					|| NUA.indexOf('ipad') > -1
					|| NUA.indexOf('ipod') > -1
					){

					let self = this
					try{
						window.webkit.messageHandlers.callback.postMessage({method: 'getContactList', params: self.contactPage});
						
					}catch(error){
						this.$emit('alertMsg', 'error', error)
						this.$emiT('alertMsg', 'error', '서비스 준비중입니다')
					}
				}
			}
			,listenIos: function(){
				
				let self = this
				
				window.addEventListener('message', function(e) {
				
					self.eventMessage = self.base64Decode(e.data)

					if(self.eventMessage.method == 'contactListReturn'){
						let data = self.eventMessage.params.data
						
						if(data.length > 0){
							self.items = self.items.concat(data)
						}else{
							console.log(11)
						}
						
						self.contactPage++
					}
				});
			}
			,searchFriends: function(call){
				
				let show = 0
				for(let index in this.items){
					let item = this.items[index]
					if((item.name).indexOf(call) > -1 || (item.tel).indexOf(call) > -1){
						item.hide = false
						show++
					}else{
						item.hide = true
					}
				}
				console.log('show: ' + show)
				if(show > 0){
					this.hideAll = false
				}else{
					this.hideAll = true
				}
			}
			,removeFav: function(item){
				console.log('removeFav')
				item.fav = false
				this.$set(this.items, item.index, item)
				localStorage.removeItem(item.tel)
			}
			,setFav: function(item){
				console.log(item)
				console.log('setFav')
				item.fav = true
				this.$set(this.items, item.index, item)
				localStorage.setItem(item.tel, true)
			}
			,setFriend: function(){
				console.log(this.list_fav)
				
				this.$emit('axios', {
					request_type: 'post'
					,request_url: '/sales/joins'
					,request_params: this.list_fav
					,authorize: true
					,callBack: 'setFriendResult'
				})
			}
			,setFriendResult: function(call){
				if(call.result){
					this.$emit('alertMsg', 'success', '해당 연락처로 URL전송이 완료되었습니다.')
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
			,base64Decode: function(json){
//				json = JSON.stringify(new URLSearchParams(json).toString());

				var APP_STORAGE = ''
				var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
				if (base64regex.test(json)) {
					APP_STORAGE = JSON.parse(window.atob(json));
					//APP_STORAGE = window.atob(json)
				} else {
					APP_STORAGE = JSON.parse(json).toString();
				}

				return APP_STORAGE
			}
		}
		,watch: {
			search: {
				handler: function(call){
					this.searchFriends(call)
				}
			}
			,callBack: {
				deep: true
				,handler: function(call){
					if(call.name == 'setFriend'){
						this.setFriend(call)
					}else if(call.name == 'setFriendResult'){
						this.setFriendResult(call)
					}
				}
			}
			,list_fav: {
				deep: true
				,handler: function(call){
					console.log('watch branch frineds list_fav')
					if(call.length > 0){
						this.program.next.do = true
					}else{
						this.program.next.do = false
					}
				}
			}
			,bottom(bottom) {
				if (bottom) {
					this.onAppEvent()
				}
			}
		}
	}
</script>

<style>
	.branch.friends .warp-star {position: relative;}
	.branch.friends .star {position: absolute; right: 0px; top: 10px;}
	.branch.friends .none {display: none;}
</style>






















